import React, { ChangeEvent, useEffect, useState } from "react";

import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { navigate } from "gatsby";
import styled from "styled-components";

import ItemsTable from "~/components/Items/ItemsTable";
import LoadingError from "~/components/Loaders/LoadingError";
import subscriptionsAPI from "~/utils/api/v2/subscriptions";
import { FREQUENCY_TRANSLATED } from "~/utils/constants/subscriptions";
import { SpecificItem } from "~/utils/interfaces/Items";

const FrequencySelect = styled(Select)`
  width: 200px;
`;

type NewItemType = {
  item_id: string;
  period: string;
  period_uses: number;
  current_period_uses: number;
};

type AddServiceProps = {
  id: string;
};

const AddService = (props: AddServiceProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [newService, setNewService] = useState<NewItemType>({
    item_id: "",
    period: "monthly",
    period_uses: 1,
    current_period_uses: 0,
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await subscriptionsAPI.addSubscriptionItem(props.id, newService);
      navigate(`/subscriptions/${props.id}`);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const handleBack = () => {
    navigate(`/subscriptions/${props.id}`);
  };

  const frequencyOptions = (
    <div>
      <FormControl margin="normal">
        <InputLabel>Frecuencia</InputLabel>
        <FrequencySelect
          autoWidth
          value={newService.period}
          onChange={(e) => {
            setNewService({
              ...newService,
              period: e.target.value as string,
            });
          }}
        >
          {Object.keys(FREQUENCY_TRANSLATED).map((key: string) => (
            <MenuItem
              value={key}
              key={key}
            >
              {FREQUENCY_TRANSLATED[key]}
            </MenuItem>
          ))}
        </FrequencySelect>
      </FormControl>
    </div>
  );

  function handlerOnChange(
    field: "frequency" | "period_uses" | "current_period_uses",
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const value = e.target.value.toLowerCase().trim();
    setNewService({
      ...newService,
      [field]: value,
    });
  }

  const handleClick = ({ id }: SpecificItem) => {
    setNewService({
      ...newService,
      item_id: id,
    });
  };

  useEffect(() => {
    if (newService.item_id !== "") {
      handleSubmit();
    }
  }, [newService.item_id]);

  return (
    <div>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <div className="flex flex-col gap-y-2">
          <div className="text-xl mb-2">Agregar un servicio para esta suscripción</div>
          {frequencyOptions}
          <TextField
            fullWidth
            type="number"
            label="Cantidad"
            variant="standard"
            onChange={(e) => handlerOnChange("period_uses", e)}
            className="w-56"
            value={newService.period_uses}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
          <ItemsTable onClick={handleClick} />
          <div className="flex flex-row justify-between mt-3">
            <ButtonGroup
              variant="outlined"
              color="primary"
            >
              <Button onClick={() => handleBack()}>Volver</Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddService;
